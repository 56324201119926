<template>
  <section class="events" data-aos="fade-up">
    <h2>Події</h2>
    <div class="load" v-if="isLoad">
      <Loader />
      <!-- <Loader /> -->
    </div>

    <p v-else-if="isLoad == false && data.length == 0">Найближчим часом немає запланованих подій</p>

    <Carousel :settings="settings" :breakpoints="breakpoints" v-else>
      <Slide v-for="(slide, index) in data" :key="index">
        <router-link :to="'/event?id=' + slide.id" class="carousel__item">
          <div class="item__content">
            <div class="main__photo">
              <img :src="slide.photo_main" />
            </div>
            <p class="class">{{ slide.category }}</p>
            <div class="item__description">
              <p class="date">{{ slide.date }} | {{ slide.time }}</p>
              <p class="name">{{ slide.name }}</p>
              <p class="adress">
                <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512">
                  <path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
                  <path
                    d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
                  />
                </svg>
                {{ slide.adress }}
              </p>
            </div>
          </div>
        </router-link>
      </Slide>

      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </section>
</template>

<script>
  import axios from "axios";
  import { ref, onBeforeMount, defineComponent } from "vue";
  import { Carousel, Navigation, Slide } from "vue3-carousel";

  import "vue3-carousel/dist/carousel.css";
  import Loader from "../global/Loader.vue";

  export default defineComponent({
    name: "WrapAround",
    components: {
      Carousel,
      Slide,
      Navigation,
      Loader,
    },
    data: () => ({
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        720: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1240: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    }),
    setup() {
      const isLoad = ref(true);
      const data = ref([]);

      const fetchData = async () => {
        try {
          let url =
            "https://sheets.googleapis.com/v4/spreadsheets/1K6j5VzAHJ-GPfuR-lhs4-8mXcpvNzkYhvw0Q-gEu97U/values/Events?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas";
          let get = await axios.get(url),
            header = get.data.values[0],
            rows = get.data.values.slice(1);

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            data.value.push(formattedRow);
          }

          setTimeout(() => {
            isLoad.value = false;
          }, 500);
        } catch (e) {
          console.log(e);
        }
      };

      onBeforeMount(() => fetchData());

      return { isLoad, data };
    },
  });
</script>

<style lang="scss" scoped>
  .events {
    margin-top: 5em;

    .load {
      width: 100%;
      height: 520px;
      display: grid;
      place-items: center;
    }

    .carousel__item {
      width: 80vw;
      max-width: 520px;
      padding: 0 0.5em;
      text-align: left;
      color: #333;

      &:hover {
        color: #fff;

        .item__content {
          .main__photo {
            opacity: 1;
          }
        }
      }

      .item__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 360px;
        padding: 1em;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 12px;
        overflow: hidden;

        .main__photo {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          filter: brightness(0.6);
          transition: 0.2s ease;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        p.class {
          position: relative;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 0.8em;
          text-transform: uppercase;

          &::before {
            position: relative;
            content: "";
            width: 6px;
            height: 6px;
            margin-right: 12px;
            border-radius: 6px;
            background: $green;
          }
        }

        .item__description {
          position: relative;

          p.date {
            text-transform: uppercase;
            // color: $mid-grey;
          }

          p.name {
            font-size: 1.6em;
          }

          p.adress {
            font-size: 0.8em;

            svg {
              width: 1em;
              height: 1em;
            }
          }
        }
      }
    }
  }
</style>
