<template>
  <div class="home">
    <Hero />
    <Cards />
    <Events />
    <Center />
  </div>
</template>

<script>
  import Cards from "../components/home/Cards.vue";
  import Center from "../components/home/Center.vue";
  import Events from "../components/home/Events.vue";
  import Hero from "../components/home/Hero.vue";

  export default {
    name: "Home",
    components: { Hero, Cards, Events, Center },
  };
</script>
