<template>
  <section data-aos="fade-up">
    <div class="cards">
      <router-link to="/places" class="card">
        <span>01</span>
        <div class="card__content">
          <p>Місця</p>
          <div class="arrow">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 476.213 476.213"
              style="enable-background:new 0 0 476.213 476.213;"
              xml:space="preserve"
            >
              <polygon
                points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5 
	405.606,308.713 476.213,238.106 "
              />
            </svg>
          </div>
        </div>
      </router-link>
      <router-link to="/spaces" class="card">
        <span>02</span>
        <div class="card__content">
          <p>Заклади</p>
          <div class="arrow">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 476.213 476.213"
              style="enable-background:new 0 0 476.213 476.213;"
              xml:space="preserve"
            >
              <polygon
                points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5 
	405.606,308.713 476.213,238.106 "
              />
            </svg>
          </div>
        </div>
      </router-link>
      <router-link to="/events" class="card">
        <span>03</span>
        <div class="card__content">
          <p>Події</p>
          <div class="arrow">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 476.213 476.213"
              style="enable-background:new 0 0 476.213 476.213;"
              xml:space="preserve"
            >
              <polygon
                points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5 
	405.606,308.713 476.213,238.106 "
              />
            </svg>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 5em;
    min-height: 420px;
    background: url(../../assets/travel.jpg) no-repeat;
    background-origin: cover;
    background-position: center center;

    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3em 2em;
      color: #fff;
      font-weight: 700;
      background: rgba(51, 51, 51, 0.6);
      box-shadow: 0 0 0 1px rgba($color: #fff, $alpha: 0.5);

      &:hover {
        .card__content {
          .arrow {
            margin-bottom: 0px;
            opacity: 1;
          }
        }

        &::before {
          height: 100%;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0%;
        left: 0;
        bottom: 0;
        background: rgba($color: #000, $alpha: 0.5);
        transition: 0.3s ease-in;
      }

      .card__content {
        z-index: 1;

        p {
          text-transform: uppercase;
        }

        .arrow {
          margin-bottom: -40px;
          opacity: 0;
          transition: 0.2s ease-in;

          svg {
            fill: #fff;
          }
        }
      }
    }
  }
</style>
