<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
  import Footer from "./components/global/Footer.vue";
  import Header from "./components/global/Header.vue";

  export default {
    name: "App",
    components: { Header, Footer },
  };
</script>

<style lang="scss">
  #app {
    position: relative;
    font-family: "Mulish", sans-serif;
    font-weight: 400, 500, 600, 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    padding-top: 150px;
    box-sizing: border-box;

    @media screen and (max-width: 720px) {
      padding-top: 100px;
    }
  }
</style>
