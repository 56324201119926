<template>
  <section data-aos="fade-up" data-aos-offset="-100">
    <a href="https://www.facebook.com/TIC.Berdychiv" target="_blank" class="center">
      <img src="@/assets/Folder.png" />
      <div class="content">
        <p class="name">Туристично інформаційний центр</p>
        <div class="arrow">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 476.213 476.213"
            style="enable-background:new 0 0 476.213 476.213;"
            xml:space="preserve"
          >
            <polygon
              points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5 
	405.606,308.713 476.213,238.106 "
            />
          </svg>
        </div>
        <p class="description">Вся необхідна інформація для туриста в одному місці</p>
      </div>
    </a>
  </section>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .center {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    place-items: center;
    margin-top: 5em;
    padding: 2em;
    color: #fff;
    background: $green;

    img {
      width: 100%;
      height: 100%;
      max-width: 280px;
      transition: 0.2s ease;
      object-fit: contain;
      object-position: center;
    }

    &:hover {
      img {
        transform: translate3d(0, -2em, 0);
      }
      .content {
        .arrow {
          transform: translate3d(2em, 0, 0);
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      grid-gap: 1rem;

      p.name {
        font-size: 2em;
        font-weight: 700;
      }

      p.description {
        grid-column: 1/-1;
      }

      .arrow {
        transition: 0.2s ease;
        svg {
          fill: #fff;
        }
      }
    }
  }
</style>
