import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/places",
    name: "Places",
    meta: { title: "Місця" },
    component: () => import("../views/Places.vue"),
  },
  {
    path: "/place",
    name: "Place",
    meta: { title: "Місце" },
    component: () => import("../views/Place.vue"),
  },
  {
    path: "/spaces",
    name: "Spaces",
    meta: { title: "Заклади" },
    component: () => import("../views/Spaces.vue"),
  },
  {
    path: "/space",
    name: "Space",
    meta: { title: "Заклад" },
    component: () => import("../views/Space.vue"),
  },
  {
    path: "/events",
    name: "Events",
    meta: { title: "Події" },
    component: () => import("../views/Events.vue"),
  },
  {
    path: "/event",
    name: "Event",
    meta: { title: "Подія" },
    component: () => import("../views/Event.vue"),
  },

  // {
  //   path: "/center",
  //   name: "Center",
  //   meta: { title: "ТІЦ" },
  //   component: () => import("../views/Center.vue"),
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "Missing",
    meta: { title: "404" },
    component: () => import("../views/Missing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | Відкрий Бердичів" : "Відкрий Бердичів";
  next();
});

export default router;
