<template>
  <header>
    <div class="header__main" :class="{ 'header__main-black': isMenu }">
      <a to="https://www.facebook.com/TIC.Berdychiv" target="_blank" class="icon">
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 504 504"
          style="enable-background:new 0 0 504 504;"
          xml:space="preserve"
        >
          <path
            d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
			C504,56.8,447.2,0,377.6,0z M319.6,252H272v156h-60V252h-32v-64h28v-27.2c0-25.6,12.8-66,66.8-66H324V148h-34.8
			c-5.6,0-13.2,3.6-13.2,16v24h49.2L319.6,252z"
          />
        </svg>
      </a>
      <nav>
        <router-link to="/places">Місця</router-link>
        <router-link to="/spaces">Заклади</router-link>
        <router-link to="/events">Події</router-link>
      </nav>
      <a href="/" class="logo"> <img src="@/assets/logo_brd.png" alt="logo"/></a>
      <nav>
        <a href="https://www.facebook.com/TIC.Berdychiv" target="_blank">ТІЦ</a>
        <a
          href="https://berdychiv-rada.gov.ua/%d0%bc%d1%96%d1%81%d1%82%d0%be/%d1%96%d1%81%d1%82%d0%be%d1%80%d1%96%d1%8f-%d0%bc%d1%96%d1%81%d1%82%d0%b0/"
          target="_blank"
          >Про місто</a
        >
        <a to="https://www.facebook.com/TIC.Berdychiv" target="_blank" class="icon">
          <svg
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 504 504"
            style="enable-background:new 0 0 504 504;"
            xml:space="preserve"
          >
            <path
              d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
			C504,56.8,447.2,0,377.6,0z M319.6,252H272v156h-60V252h-32v-64h28v-27.2c0-25.6,12.8-66,66.8-66H324V148h-34.8
			c-5.6,0-13.2,3.6-13.2,16v24h49.2L319.6,252z"
            />
          </svg>
        </a>
      </nav>

      <div class="m__button" :class="{ active: isMenu }" @click="isMenu = !isMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div class="mobile__menu" :class="{ active: isMenu }">
      <nav>
        <router-link to="/places">Місця</router-link>
        <router-link to="/spaces">Заклади</router-link>
        <router-link to="/">Події</router-link>
        <a href="https://www.facebook.com/TIC.Berdychiv" target="_blank">ТІЦ</a>
        <a
          href="https://berdychiv-rada.gov.ua/%d0%bc%d1%96%d1%81%d1%82%d0%be/%d1%96%d1%81%d1%82%d0%be%d1%80%d1%96%d1%8f-%d0%bc%d1%96%d1%81%d1%82%d0%b0/"
          target="_blank"
          >Про місто</a
        >
      </nav>
    </div>
  </header>
</template>
<script>
  import { ref } from "vue";
  export default {
    setup() {
      const isMenu = ref(false);

      window.onscroll = () => {
        if (!window.scrollY) return;
        let header = document.querySelector(".header__main");
        window.pageYOffset > 50 ? header.classList.add("scroll") : header.classList.remove("scroll");
      };

      return { isMenu };
    },
  };
</script>
<style lang="scss" scoped>
  header {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 99;

    .header__main {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 100px 1fr;
      place-items: center;
      grid-gap: 1em;
      width: 100%;
      max-width: 1140px;
      height: 100px;
      margin: 0 auto;
      padding: 0 2em;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
      pointer-events: all;
      z-index: 101;

      &.scroll {
        height: 60px;
        transition: 0.2s ease;
      }

      &-black {
        transition: 0.2s 0.1s ease-in;
        background: #000;

        & > .icon {
          svg {
            fill: #fff;
          }
        }
      }

      @media screen and (max-width: 720px) {
        width: calc(100% - 20px);
        height: 60px;
        margin: 10px;
        padding: 0;

        &.scroll {
          height: 60px;
        }
      }

      & > .icon,
      .m__button {
        @media screen and (min-width: 720px) {
          display: none;
        }
      }

      nav {
        display: flex;
        width: 100%;

        @media screen and (max-width: 720px) {
          display: none;
        }

        &:nth-child(2) {
          justify-content: flex-start;
        }

        &:nth-child(4) {
          justify-content: flex-end;
        }

        a {
          color: #333;
          margin: 0 1em;
          padding-top: 0.2em;

          &:hover {
            color: $acs;
          }
        }
      }

      .m__button {
        position: relative;
        width: 50px;
        height: 50px;

        &:hover {
          cursor: pointer;

          span {
            background: $acs;
          }
        }

        &.active {
          span {
            background: #fff;
          }
          span:nth-child(1) {
            transform: translate3d(10px, 25px, 0) rotate(45deg);
          }
          span:nth-child(2) {
            transform: translate3d(10px, 25px, 0) rotate(-45deg);
          }
          span:nth-child(3) {
            transform: translate3d(25px, 25px, 0) rotate(45deg);
          }
        }

        span {
          position: absolute;
          height: 2px;
          background: #333;
          border-radius: 2px;
          transition: 0.2s ease-in-out;

          &:nth-child(1) {
            width: 15px;
            transform: translate3d(10px, 10px, 0);
            transform-origin: center right;
          }
          &:nth-child(2) {
            width: 30px;
            transform: translate3d(10px, 25px, 0);
          }
          &:nth-child(3) {
            width: 15px;
            transform: translate3d(25px, 38px, 0);
            transform-origin: center left;
          }
        }
      }

      a.logo {
        position: relative;
        display: block;
        width: 100px;
        height: 100%;

        img {
          position: absolute;
          display: block;
          width: 80%;
          height: 80%;
          top: 10%;
          left: 10%;
          bottom: 10%;
          right: 10%;
          object-fit: contain;
        }
      }
    }

    .mobile__menu {
      position: absolute;
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 100px 3vw 3vh;
      background: #000;
      pointer-events: all;
      transform: translate3d(0, -110%, 0);
      transition: 0.1s ease-in-out;

      &.active {
        transform: translate3d(0%, 0, 0);
        transition: 0.3s ease-in-out;
      }

      nav {
        a {
          font-size: 2rem;
          margin-bottom: 1rem;

          color: #fff;
        }
      }
    }
  }
</style>
