<template>
  <div class="hero">
    <div class="hero__images">
      <div class="hero__image" :class="{ active: isSlide == 0 }">
        <div class="hero__image-image">
          <img src="@/assets/slider/sl1.jpg" alt="slide_1" />
        </div>
        <div class="hero__image-content">
          <router-link to="/places">Пам'ятки</router-link>
        </div>
      </div>
      <div class="hero__image" :class="{ active: isSlide == 1 }">
        <div class="hero__image-image">
          <img src="@/assets/slider/sl3.jpg" alt="slide_3" />
        </div>
        <div class="hero__image-content">
          <router-link to="/places">Пам'ятники</router-link>
        </div>
      </div>
      <div class="hero__image" :class="{ active: isSlide == 2 }">
        <div class="hero__image-image">
          <img src="@/assets/slider/sl2.jpg" alt="slide_2" />
        </div>
        <div class="hero__image-content">
          <router-link to="/places">Музеї</router-link>
        </div>
      </div>
    </div>
    <div class="hero__slide">
      <div class="hero__slide-section" @click="isSlide = 0" :class="{ active: isSlide == 0 }">
        <span></span>
        <p>01</p>
      </div>
      <div class="hero__slide-section" @click="isSlide = 1" :class="{ active: isSlide == 1 }">
        <span></span>
        <p>02</p>
      </div>
      <div class="hero__slide-section" @click="isSlide = 2" :class="{ active: isSlide == 2 }">
        <span></span>
        <p>03</p>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, watch } from "vue";

  export default {
    setup() {
      const isSlide = ref(0);

      watch(
        () => isSlide.value,
        () => {
          let slider = document.querySelector(".hero__images");
          let image = document.querySelector(".hero__image").offsetWidth;

          slider.style.transform = `translate3d(-${isSlide.value * image}px, 0 ,0)`;
        }
      );

      const slider = () => {
        setTimeout(() => {
          isSlide.value < 2 ? (isSlide.value += 1) : (isSlide.value = 0);

          slider();
        }, 8000);
      };

      slider();

      return { isSlide };
    },
  };
</script>
<style lang="scss" scoped>
  .hero {
    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 100vh;
    margin-top: -150px;
    overflow: hidden;

    .hero__images {
      position: absolute;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 300vw;
      height: 100%;
      top: 0;
      left: 0;
      transition: 0.5s ease-in-out;

      .hero__image {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding-bottom: 100px;

        &.active {
          .hero__image-content {
            transform: translate3d(0, 0%, 0);
            transition: 0.3s 0.5s ease-in-out;
            opacity: 1;
          }
        }

        &-image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%);
            z-index: 1;
          }
        }

        &-content {
          width: 100%;
          max-width: 1140px;
          margin: 0 auto;
          padding: 0 3vw;
          z-index: 2;
          transform: translate3d(0, 50%, 0);
          transition: 0s;
          opacity: 0;

          a {
            color: #fff;
            font-size: 3em;
            font-weight: 600;
          }
        }
      }
    }

    .hero__slide {
      display: flex;
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 3vw 2em;
      z-index: 1;

      &-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100px;
        height: 50px;
        cursor: pointer;

        &.active {
          span::after {
            width: 100%;
            transition: 8s linear;
          }
        }

        &:hover {
          p {
            color: rgba(255, 255, 255, 1);
          }
        }

        span {
          position: relative;
          display: block;
          width: 100%;
          height: 2px;
          background: rgba(255, 255, 255, 0.3);

          &::after {
            position: absolute;
            content: "";
            width: 0%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 1);
          }
        }

        p {
          color: rgba(255, 255, 255, 0.3);
          font-weight: 500;
          transition: 0.2s ease-in;
        }
      }
    }
  }
</style>
